import { graphql } from "gatsby"
import React from "react"
import Bio from "../components/bio"
import FeatureTags from "../components/feature_tags"
import Layout from "../components/layout"
import Post from "../components/post"
import Section from "../components/section"
import Seo from "../components/seo"

const Tags = ({ pageContext, data }) => {
  return (
    <Layout>
      <Seo title={`「${pageContext.tag}」がタグ付けされた記事一覧`}></Seo>
      <Section>
        <Bio />
      </Section>
      <h1>
        {pageContext.tag} ({data.allMdx.totalCount}件)
      </h1>
      {data.allMdx.edges.map(({ node }) => {
        return (
          <Post
            slug={node.fields.slug}
            title={node.frontmatter.title}
            date={node.frontmatter.date}
            tags={node.frontmatter.tags}
          />
        )
      })}
      <Section>
        <FeatureTags />
      </Section>
    </Layout>
  )
}

export default Tags
export const pageQuery = graphql`
  query($tag: String) {
    allMdx(
      limit: 1000
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { tags: { in: [$tag] } } }
    ) {
      totalCount
      edges {
        node {
          excerpt(truncate: true)
          fields {
            slug
          }
          frontmatter {
            title
            date
            tags
          }
        }
      }
    }
  }
`
